import React, { useEffect, useState } from 'react';
import styles from './LengthInformer.module.css';

export interface LengthInformerProps {
    length: number;
}

export function LengthInformer({ length }: LengthInformerProps): JSX.Element | null {
    const [len, setLen] = useState<number | null>(null);

    useEffect(() => {
        setLen(length);
    }, [length]);

    return len === null ? null : (
        <div className={styles.informer}>
            <div className={styles['informer-content-wrapper']}>
                <div className={styles['informer-content-figure']}>{len}</div>
                <div className={styles['informer-content-km']}>km</div>
            </div>
        </div>
    );
}
