import React from 'react';
import { Layout } from 'antd';
import { BikeLanesMap } from '../../components/BikeLanesMap/BikeLanesMap';
import * as config from '../../config/config.json';

const { Content } = Layout;

export function Main(): JSX.Element {
    const { lat, lon } = config.map.center;
    const {
        map: { zoom, maxArea }
    } = config;

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Content>
                <BikeLanesMap center={[lat, lon]} zoom={zoom} maxArea={maxArea} />
            </Content>
        </Layout>
    );
}
