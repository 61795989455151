import React from 'react';
import './App.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { Main } from './pages/Main/Main';

function App(): JSX.Element {
    return (
        <div className="App">
            <Main />
        </div>
    );
}

export default App;
