import { LineString, MultiLineString } from 'geojson';
import { Polygon } from 'leaflet';
import { POST } from '../util/request/makeRequest';

export interface IStreetsResponse {
    streets: (LineString | MultiLineString)[];
    length: number;
}

export interface IStreet {
    type: string;
    coordinates: Record<number, number>[][] | Record<number, number>[][][];
}

export function getStreetsByPolygon(dataType: string, polygon: Polygon): Promise<IStreetsResponse> {
    return POST({
        url: 'v2/streets-by-bounds',
        data: {
            dataType,
            polygon
        }
    });
}
