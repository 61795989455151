export const METHOD_GET = 'GET';
export const METHOD_POST = 'POST';

export interface POSTParams {
    url: string;
    data: Record<string, unknown>;
}

export interface IMakeRequestParams {
    url: string;
    method?: string;
    headers?: Headers;
    data?: Record<string, unknown>;
    queryParams?: Record<string, unknown>;
    queryString?: string;
    isExternal?: boolean;
    logOn?: boolean;
}

export async function POST<T>(params: IMakeRequestParams): Promise<T> {
    const { url, data } = params;

    const response = await fetch(url, {
        method: METHOD_POST,
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });

    if (!response.ok) {
        throw Error('Bad response from the server. Try again');
    }

    const json = await response.json();

    const { result }: { result: T } = json;

    return result;
}
