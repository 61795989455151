import React, { useEffect, useRef, useState } from 'react';
import * as L from 'leaflet';
import 'leaflet-easybutton';
import { GeometryUtil, LatLng, LatLngExpression, LeafletEvent, Map as LeafLetMap, Polygon } from 'leaflet';
import { FeatureGroup, MapContainer, Polyline, TileLayer } from 'react-leaflet';
import { LineString, MultiLineString } from 'geojson';
import { EditControl } from 'react-leaflet-draw';
import { notification, Spin } from 'antd';
import { getStreetsByPolygon } from '../../services/backend';
import { LengthInformer } from '../LengthInformer/LengthInformer';

const SQM_IN_SQKM = 1000000;

interface BikeLanesMapProps {
    center: LatLngExpression;
    zoom: number;
    maxArea: number;
}

const createLineStringGeometry = (street: LineString): LatLng[] => {
    return street.coordinates.map((coords) => {
        return new LatLng(coords[1], coords[0]);
    });
};

const createMultiLineStringGeometry = (street: MultiLineString): LatLng[][] => {
    return street.coordinates.map((items) => {
        return items.map((coords) => {
            return new LatLng(coords[1], coords[0]);
        });
    });
};

const createGeometry = (street: LineString | MultiLineString): LatLng[] | LatLng[][] => {
    return street.type === 'LineString' ? createLineStringGeometry(street) : createMultiLineStringGeometry(street);
};

export const BikeLanesMap = ({ center, zoom, maxArea }: BikeLanesMapProps): JSX.Element => {
    const [bounds, setBounds] = useState<Polygon>();
    const [lines, setLines] = useState<(LatLng[] | LatLng[][])[]>([]);
    const [length, setLength] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [map, setMap] = useState<LeafLetMap>();
    const [dataType, setDataType] = useState<'car' | 'merged'>('merged');

    // add a bike/car button when the map ready
    useEffect(() => {
        if (!map) {
            return;
        }

        const dataTypeButton = L.easyButton({
            id: 'toggle-car',
            states: [
                {
                    icon: '<img src="/bicycle.svg" style="width: 36px; margin-top: 10px; margin-bottom: 10px;">',
                    title: 'Bike',
                    stateName: 'bike',
                    onClick: (b: L.Control.EasyButton) => {
                        setDataType('car');
                        b.state('car');
                    }
                },
                {
                    icon: `<img src="/car.svg" style="width: 36px; margin-top: 6px; margin-bottom: 6px;">`,
                    title: 'Car',
                    stateName: 'car',
                    onClick: (b) => {
                        setDataType('merged');
                        b.state('bike');
                    }
                }
            ]
        });

        dataTypeButton.addTo(map);
    }, [map]);

    // fetch geometries from the backend when the bounds are set or bike/car state changed
    useEffect(() => {
        if (!bounds) {
            return;
        }

        setLoading(true);

        getStreetsByPolygon(dataType, bounds)
            .then((result) => {
                const geometries = result.streets.map((street) => createGeometry(street));
                setLines(geometries);

                // eslint-disable-next-line no-magic-numbers
                setLength(Math.round((result.length / 1000) * 100) / 100);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [bounds, dataType]);

    const zoomLevelRef = useRef(zoom);

    useEffect(() => {
        map?.on('zoom', () => {
            zoomLevelRef.current = map?.getZoom();
        });
    }, [map]);

    const onCreated = (e: LeafletEvent): void => {
        // compute area in sqkm
        const area = Math.round(GeometryUtil.geodesicArea(e.layer._latlngs[0]) / SQM_IN_SQKM);
        if (area > maxArea) {
            notification.warn({
                message: 'Warning',
                description:
                    `Selected area is too large: ${area} sq.km. ` +
                    `Please select a smaller area, max size is: ${maxArea} sq.km.`
            });
            return;
        }

        setBounds(e.layer._latlngs);
        setDataType('merged');
    };

    return (
        <Spin spinning={loading}>
            <div className="map__container">
                <LengthInformer length={length} />
                <MapContainer center={center} zoom={zoom} style={{ height: '100vh' }} zoomControl whenCreated={setMap}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <FeatureGroup>
                        <EditControl
                            position="topright"
                            draw={{
                                rectangle: false,
                                circle: false,
                                polygon: true,
                                polyline: false,
                                marker: false,
                                circlemarker: false
                            }}
                            onCreated={(e: LeafletEvent) => onCreated(e)}
                        />
                    </FeatureGroup>
                    {lines.map((geom) => {
                        return <Polyline positions={geom} color="red" />;
                    })}
                </MapContainer>
            </div>
        </Spin>
    );
};
